<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">ADD TRAVEL REQUEST ASIGNEE</span>
        </v-card-title>
        <v-layout wrap justify-start v-if="employeesArray.length > 0">
          <v-flex xs12>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      " class="text-left">
                      Select
                    </th>
                    <th style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      " class="text-left">
                      Name
                    </th>
                    <th style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      " class="text-left">
                      Employee ID
                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr class="text-left align-center" v-for="(item, i) in employeesArray" :key="i">
                    <td class="py-4">
                      <v-checkbox v-model="travelAssignee" :value="item._id" @click="addAssignee(travelAssignee)"
                        :hide-details="true"></v-checkbox>
                    </td>
                    <td>
                      <span v-if="item.name" class="" style="font-family: kumbhMedium">{{ item.name }}</span>
                    </td>
                    <td>
                      <span v-if="item.employeeId" class="" style="font-family: kumbhMedium">{{ item.employeeId }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
          <!-- <v-flex xs12 py-5>
            <v-btn color="#005f32" dark @click="addAssignee()"><span
                style="font-family: kumbhRegular">Submit</span></v-btn>
          </v-flex> -->
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
            v-model="currentPage" color="#005f32"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      employeesArray: [],
      travelAssignee: [],
      travelRequestAssignees: [],
      data: [],
      assignedEmployees: [],
      name: null,
      assetId: null,
      travelAssign:[],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getUpdates();
  },
  methods: {
    getArray(item) {
      this.travelRequestAssignees.push(item);
      // console.log("v-model", this.travelAssignee, item)
      // this.$store.commit("assignee", this.travelAssignee);
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeesArray = response.data.data;
          this.getUpdates();
          this.totalData = response.data.length;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addAssignee(item) {
      this.appLoading = true;
      axios({
        url: "/organisation/settings/travelRequest/assignees/update",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          travelRequestAssignees: item,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showsnackbar = true
          this.getUpdates();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getUpdates() {
      this.appLoading = true;
      axios({
        url: "/organisation/settings/travelRequest/assignees/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assignedEmployees = response.data.travelRequestAssignees;
          // if (this.$store.state.assignee.length) {
          //   this.travelAssignee = this.$store.state.assignee
          //   console.log("data store", this.$store.state.assignee)
          // }
          // else
            this.travelAssignee = this.assignedEmployees;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>